import React from 'react'
import Head from '@/next/head'
import { AppPage } from '@/models/AppPage'
import DefaultPage from '@/layouts/DefaultPage'
import { customTitle } from '@/utils/customTitle'
import { ContentHeader } from '@/components/ContentHeader'

const Page: AppPage = () => {
  return (
    <>
      <Head>
        <title>{customTitle('Перевод по реквизитам')}</title>
      </Head>
      <DefaultPage hideMobileHeader>
        <ContentHeader title="Перевод по реквизитам" />
      </DefaultPage>
    </>
  )
}
export default Page
